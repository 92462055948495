@import "styles/config";
@import "styles/utilities";


// General Styles


* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	
}

// Logo
#logo {
	width: 10rem;
	height: 5rem;
	cursor: pointer;
	display: flex;
	align-items: center;

}

// Header

  .nav-container {
background: white;



	.navbar-link {
		color: black;
		font-size: 1.3rem;
		letter-spacing: 2px;
	


		&:hover {
			color: grey;
		}
	  }

  
  }


  a {
	text-decoration: none;
	color: white;
	text-transform: uppercase;
  }

  .navbar-brand {
	  margin-right: 0 !important;
  }


 


// Navbar
 .effect .navbar-link:before {
	content: "[" ;
	left: -5px ;
  }
  
 .effect .navbar-link:after  {
	content: "]" ;
	right: -5px ;
  }
  
  .effect .navbar-link:before,
  .effect .navbar-link:after {
	position: relative;
	opacity: 0;
	color: black;
	// top: -1px;
	transition: all .5s;
  }
  
  .effect .navbar-link:hover:before,
  .effect .navbar-link:hover:after {
	opacity: 0.7;
  }
  
  .effect .navbar-link:hover:before  {
	left: -20px;
  }
  
  .effect .navbar-link:hover:after {
	right: -20px;
  }


//   .nav-active {
// 	  color: grey !important;
//   }
 

	.fa-bars {
		color: black;
		font-size: 3rem;
	}

	.menu-icon {
		display: none;
	
	}

	.nav-menu {
		display: flex;
		background-color: white;
		justify-content: space-around;
		align-items: center;
		position: fixed;
		flex-direction: row;
		top: 0;
		right: 0;
		left: 0;
    z-index: 1030;
	}


	.nav-item {
		height: 100px;
		position: relative;

	
	}



	.nav-links {
		color: #fff;

		height: 100%;
		position: relative;


	}

	.navbar-logo.mobile {
		display: none;
	}

	.navbar-logo.desktop {
		display: block;
	}





// Header Content Hero Section
.header-container {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	// object-fit: contain;
	justify-content: center;
}

video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: fixed;
}



.header-container.space-top {
	padding-top: 140px;
	background-color: white;

	.hero-btns {
		z-index: 2;

		.play-button,
		.pause-button {
			display: none;
		}
	}
}

// About
#about {
	.about-info {
		display: grid;
		grid-template-areas:
			"aboutimg heading"
			"aboutimg aboutdesc";
		gap: 0.2rem;
	}

	//Grid Area
	.heading {
		grid-area: heading;
		align-self: center;
		justify-self: center;
		margin-bottom: 1.5rem;
		letter-spacing: 2px;
	}

	
	.about-image {
		grid-area: aboutimg;
		height: 640px;
		width: 480px;
	}

	.about-description {
		grid-area: aboutdesc;
		width: 35rem;
		align-self: start;
		justify-self: center;
		line-height: 26px;
	}
}

// Projects
.projects {
	display: grid;
	gap: 3rem;
	grid-template-columns: repeat(2, 1fr);
	box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.3);
	margin-top: 4rem;
}

// .project {
// 	position: relative;
// 	overflow: hidden;
// }


// Contact Page
#contact {
	.text-fields {
		display: grid;
		grid-template-areas:

			"name subject"
			"email phone"
			"message message";
		gap: 1.2rem;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;
		// Grid Areas
		// .name-label {
		// 	grid-area: nameLabel;
		// }
		.name-input {
			grid-area: name;
		}
		.email-input {
			grid-area: email;
		}
		.subject-input {
			grid-area: subject;
		}
		.phone-input {
			grid-area: phone;
		}
		.message-input {
			grid-area: message;
			height: 130px;
		}

		.text-input {
			padding: 0.5rem 1rem;
		}
	}

	button[type="submit"] {
		width: 50%;
	}
}

// Footer
.footer-container {
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	margin-top: auto;
	z-index: 2;
}

.footer-content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3rem;
}

.socials-link {
	color: white;
	font-size: 30px;
	padding: 0.8rem;

	&:hover {
		color: grey;
	}

}

.copyright {
	margin-bottom: 0.5rem!important;
}

// Success page
.success-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	font-size: 1rem;

	.green-tick {
		height: 140px;
		width: 140px;
		margin-bottom: 2rem;
	}
}

.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

// Import Media Queries
@import "styles/media";
