.container {
	max-width: $website-width;
	padding: 0 1.5rem;
	margin: auto;
	overflow: hidden;
}

.text-center {
	text-align: center;
}

.space-top.pb-3 {
	 padding-top: 180px;
}

// Padding
.py {
	&-1 {
		padding: 1rem 0;
	}
	&-2 {
		padding: 2rem 0;
	}
	&-3 {
		padding: 3rem 0;
	}
	&-4 {
		padding: 4rem 0;
	}
}

// Padding
.pb {
	&-1 {
		padding: 1rem 0;
	}
	&-2 {
		padding: 2rem 0;
	}
	&-3 {
		padding: 3rem 0;
	}
	&-4 {
		padding: 4rem 0;
	}
}

// Buttons
// extend the selector to different button classes
%btn-shared {
	display: inline-block;
	padding: 0.8rem 2rem;
	transition: all 0.5s;
	border: none;
	cursor: pointer;
}

.btn {
	&-dark {
		@extend %btn-shared;
		color: #f4f4f4;
		background: black;

		&:hover {
			background-color: $light-color;
			color: black;
			border: 1px solid black;
		}
	}

	&-light {
		@extend %btn-shared;
		color: black;
		background-color: #f4f4f4;
		padding: 1rem;
		font-size: 1.5rem;
		border-radius: 5px;
		&:hover {
			background-color: black;
			color: white;
			border: 1px solid white;
		}
	}
}

// Other
.section-title {
	font-size: 2rem;
	display: block;
	padding-bottom: 0.5rem;
	text-align: center;
	font-weight: 350;
	letter-spacing: 2px;
}

.underline {
	height: 2px;
	width: 3rem;
	background: $medium-color;
	display: block;
	margin: 0 auto 1rem auto;
}

.sub-text {
	font-size: 1rem;
	margin-bottom: 2rem;
	font-weight: 200;
}
