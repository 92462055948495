// Tablets and smaller
@media screen and (max-width: 800px) {

	.header-container.space-top {
		padding-top: 0;
	}

	.effect .navbar-link:before,
	.effect .navbar-link:after {
	  display: none;
	}

	// Nav bar
	.nav-container {
		width: 100%;
		justify-content: space-around;
		position: fixed;
		z-index: 9999;

		.nav-menu {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 90vh;
			position: absolute;
			 top: 138px;
			left: -100%;
			// opacity: 1;
			grid-gap: 50px;
			justify-content: start;
		}
		.nav-menu a:nth-of-type(3) {
			display: none;
		}
		.nav-menu.active {
			top: 80px;
			background: black !important;
			margin-top: 1rem;
			left: 0;
			// opacity: 1;
			transition: all 0.5s ease;
			z-index: 20;
	 	}

		.navbar-link {
			text-align: center;
			padding: 2rem;
			width: 100%;
			display: table;
			color: white;
		}

		.navbar-link:hover {
			background-color: grey;
			color: white;
			
			border-radius: 0;
		}

		.navbar-logo.mobile {
			display: block;
		}

		.navbar-logo.desktop {
			display: none;
		}

		.menu-icon {
			display: block;
			cursor: pointer;
		}

		.fa-times {
			color: black;
			font-size: 3rem;
		}

	

	}

	.header-container.space-top {
		.hero-btns {
			.vid-controls {
				margin-top: 3rem;
				display: flex;
				justify-content: space-around;
				align-items: center;
				cursor: pointer;
				.play-button,
				.pause-button {
					display: inline;
					color: white;
					font-size: 80px;

					&:hover {
						color: grey;
					}
				}
			}
		}
	}

	


}

// Android screens plays homepage video perfectly, remove play button
@media screen and (max-width: 800px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
	.header-container.space-top {
		.hero-btns {
			.vid-controls {
				display: none;
			}
		}
	}
}

// Smartphones
@media (max-width: 500px) {
	//Projects
	// Stack grid
	.projects {
		grid-template-columns: 1fr;
	}

	// About
	#about {
		.about-info {
			display: block;

			.about-image {
				width: 100%;
				height: 100%;
			}
			.about-description {
				width: 100%;
				margin-top: 2rem;
			}
		}
	}



	// Contact
	#contact {
		.text-fields {
			grid-template-areas:
				"name"
				"subject"
				"email"
				"phone"
				"message";
		}
	}
}
